import Alpine from "alpinejs"

declare global {
    const archiveData: {
        apiUrl: string
        totalCount: number
        order: string
        posts: string[]
        page: number
        nextPageUrl: string | null
        pager: string
        filter: Object[]
        queryParams: Object
        queryParamsOrder: string[]
        categories: Object[] | null
    }
}

const setupSectionCollapse = (section) => {
    let collapseAfter = 6,
        collapseWhileOver = 8

    if (section.key === (Alpine.store('Archive')?.queryParamsOrder['stav'] ?? 'stav'))
        collapseAfter = collapseWhileOver = 2

    section.visibleCount = section.fields?.length >= collapseWhileOver + 1 ? collapseAfter : collapseWhileOver

    let collapsedCount = 0
    section.fields?.forEach((field, index) => {
        (!field.value && index >= collapseAfter) && collapsedCount++
    })

    section.shouldCollapse = !section.hasOwnProperty('render')
        && section.key !== (Alpine.store('Archive')?.queryParamsOrder['rada'] ?? 'rada')
        && section.key !== (Alpine.store('Archive')?.queryParamsOrder['model'] ?? 'model')
        && section.fields?.length > collapseWhileOver && collapsedCount > 0
}

export const Archive = {
    apiUrl: '',
    initialized: false,
    isLoading: false,
    queryParams: {},
    pendingQueryParams: {},
    queryParamsOrder: [],
    order: null,
    totalCount: 0,
    posts: [],
    appendPosts: false,
    categories: {},
    page: 1,
    pager: "",
    filter: null,
    filterUrl: null,
    nextPageUrl: null,
    fetchController: new AbortController(),

    setQueryParams(params = {}, resetPager = false, resetQuery = false, scrollIntoView = false) {
        if (this.isLoading) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        const queryParams = params ? {
            ...(resetQuery ? {} : this.queryParams),
            ...(resetQuery ? {} : this.pendingQueryParams),
            ...params,
            ...(resetPager ? { [this.queryParamsOrder['strana']]: 1 } : {})
        } : {}

        document.querySelectorAll('[x-bind="ArchiveQuickLink"]')
            .forEach(el => el.classList.remove('is-active'))

        this.pendingQueryParams = {...this.pendingQueryParams, ...(params ?? {})}
        this.loadArchive(queryParams, scrollIntoView)
    },

    changeFilterTerm(key, changedField) {
        const termSection = this.filter.find(section => section.key === key) ?? {}
        this.setQueryParams({[key]: termSection.fields ? termSection.fields.reduce(
                (values, term) => !term.value || (changedField.incompatible ?? []).includes(term.key)
                    ? values : [...values, term.key], []) : '' }, true);
    },

    changeFilterSelect(key, value) {
        this.setQueryParams({[key]: value}, true);
    },

    removeFilterTerm(key, value) {
        this.setQueryParams({[key]: Array.isArray(this.queryParams[key]) ?
                this.queryParams[key].filter(term => term !== value) : '' }, true);
    },

    removeFilterTermMulti(keys) {
        const terms = {};
        keys.forEach(key => terms[key] = '');

        this.setQueryParams(terms)
    },

    resetFilter() {
        this.setQueryParams(null)
    },

    changeCategory(category: string) {
        let activeCategory = this.queryParams[this.queryParamsOrder['kategorie']] ?? [];
        if(!Array.isArray(activeCategory)) activeCategory = activeCategory.split(',');

        !category ? this.resetFilter() : this.setQueryParams({
            [this.queryParamsOrder['kategorie']]: activeCategory.includes(category)
                ? activeCategory.filter((cat: string) => cat !== category) : [...activeCategory, category]
        }, true)
    },

    changeOrder(order = 'DESC', orderBy = '') {
        this.setQueryParams({
            [this.queryParamsOrder['razeni']]: order,
            [this.queryParamsOrder['razeni_podle']]: orderBy
        })
    },

    changePage(page: number) {
        this.setQueryParams({ [this.queryParamsOrder['strana']]: page })
    },

    loadNextPage() {
        this.appendPosts = true
        this.setQueryParams({ [this.queryParamsOrder['strana']]: this.page + 1 })
    },

    async loadArchive(queryParams = {}, scrollIntoView = false) {
        this.isLoading = true;
        let query = new URLSearchParams();

        if (this.queryParamsOrder?.length) {
            const orderedQuery = Object.values(this.queryParamsOrder)
                .reduce((object: object, key: string) => {
                    return {...object, [key]: ""}
                }, {}) as { [key: string]: string }

            Object.entries(queryParams).forEach(([key, value]) => {
                const assignedKey = this.queryParamsOrder[key] ?? key
                if (orderedQuery.hasOwnProperty(assignedKey))
                    orderedQuery[assignedKey] = Array.isArray(value) ? value.join(',') : value.toString()
            })

            query = new URLSearchParams(orderedQuery)
        } else {
            Object.entries(queryParams).forEach(([key, value]) => {
                query.append(key, Array.isArray(value) ? value.join(',') : value.toString())
            })
            query.sort()
        }

        const defaultValues = {
            'kategorie': 'all',
            'strana': '1',
            'najeto': '0',
            'rok_od': '1970',
            'rok_do': '2999',
            'zamereni': document.body.dataset.site
        }

        Object.entries(defaultValues).forEach(([key, value]) => {
            if (this.queryParamsOrder.hasOwnProperty(key) && query.get(this.queryParamsOrder[key]) === value)
                query.delete(this.queryParamsOrder[key])
        });

        [...query.entries()].forEach(([key, value]) => {
            !value && query.delete(key)
        });

        const queryString = [...query].length ? `?${decodeURIComponent(query.toString())}` : '';
        const targetUrl = `${location.pathname}${queryString}`;

        await fetch(`${this.apiUrl}${queryString}`, { signal: this.fetchController.signal })
            .then((response) => response.json())
            .then((data) => {
                this.posts = !this.appendPosts ? data.posts : [...this.posts, ...data.posts];
                this.categories = data.categories ?? {}
                this.totalCount = data.totalCount
                this.page = data.page
                this.pager = data.pager
                this.nextPageUrl = data.nextPageUrl

                data.filter && this.filter.forEach(section => {
                    const refreshedSection = data.filter.find(e => e.key === section.key)
                    section.fields && section.fields.forEach((term, index) => {
                        section.fields[index] = {...term, ...(refreshedSection.fields?.find(e => e.key === term.key) ?? {})}
                    })

                    if (section.type === "term") setupSectionCollapse(section)
                    if (section.type === "select") section.selected = refreshedSection.selected
                    if (section.type === "range" || section.type === "range-select") section.data = refreshedSection.data
                })

                scrollIntoView && !this.appendPosts
                    && document.querySelector('.OffersCatalog-inner')?.scrollIntoView()

                this.filterUrl = targetUrl

                !Object.hasOwn(queryParams, 'noHistory') && history.pushState({}, '', targetUrl)
                delete queryParams.noHistory

                if (data.title && data.description) {
                    document.querySelector('h1').textContent = data.title

                    const metaTitle = document.querySelector('title')
                    metaTitle.textContent = `${data.title} ${metaTitle.textContent.substring(metaTitle.textContent.indexOf("|"))}`

                    document.querySelector('meta[name="description"]')
                        .setAttribute("content", data.description)
                }

                this.isLoading = this.appendPosts = false
                this.queryParams = queryParams
                this.pendingQueryParams = {}
            })
            .catch(e => (e.name !== 'AbortError') && location.assign(targetUrl))
    },

    init() {
        if (!archiveData) return;

        this.apiUrl = archiveData.apiUrl ?? ''
        this.queryParams = archiveData.queryParams ?? {}
        this.queryParamsOrder = archiveData.queryParamsOrder ?? []
        this.order = archiveData.order ?? {}
        this.totalCount = archiveData.totalCount ?? 0
        this.posts = archiveData.posts ?? []
        this.categories = archiveData.categories ?? {}
        this.page = archiveData.page ?? 1
        this.pager = archiveData.pager ?? ""
        this.filter = archiveData.filter ?? null
        this.nextPageUrl = archiveData.nextPageUrl ?? null

        this.filterUrl = Alpine.$persist('').as('offersFilterUrl').using(sessionStorage)

        window.addEventListener("popstate", (e) => {
            const target = e.target as Window
            if (!target) return

            const queryParams = {};
            const searchParams = new URLSearchParams(target.location.search)

            searchParams.forEach((value, key) => {
                queryParams[key] = value.split(",")
            })

            this.setQueryParams({...queryParams, noHistory: true }, false, true)
        })
    }
}

export const ArchiveFilter = () => ({
    sections: (Alpine.store('Archive').filter ?? []).map(section => {
        if (section.type === 'term') {
            section.allVisible = (section.key === (Alpine.store('Archive')?.queryParamsOrder['rada'] ?? 'rada')
                || section.key === (Alpine.store('Archive')?.queryParamsOrder['model'] ?? 'model')) ?? false
            setupSectionCollapse(section)
        }

        return section
    }),

    term: {
        [':name']() { return `${this.section.key}[]` },
        [':value']() { return this.field.key },
        [':disabled']() { return this.field.hasOwnProperty('count') && this.field.count === 0 },
        ['@change']() {
            this.$nextTick(() => { Alpine.store('Archive').changeFilterTerm(this.section.key, this.field) })
        }
    },
    termCheck: {
        [':class']() { return { 'is-disabled': this.field.hasOwnProperty('count') && this.field.count === 0 } },
        ['x-show']() { return this.section.allVisible || this.fieldIndex < this.section.visibleCount || !!this.field.value }
    },
    toggleTermCollapse: {
        [':class']() { return { 'is-opened': this.section.allVisible }},
        ['@click']() { this.section.allVisible = !this.section.allVisible }
    },
    select: {
        [':name']() { return this.section.key },
        ['@change']() {
            this.$nextTick(() => { Alpine.store('Archive').changeFilterSelect(this.section.key, this.$el.value) })
        }
    }
})

export const ArchiveFilterRange = (section) => ({
    key: section.key,
    data: section.data,
    step: section.step,
    min: 0,
    max: 0,

    get minimum() { return Math.min(this.data.min, this.data.minValue) },
    get maximum() { return Math.max(this.data.max, this.data.maxValue) },
    init() {
        this.min = this.data.minValue
        this.max = this.data.maxValue

        Alpine.effect(() => {
            const range = (Alpine.store('Archive').filter ?? []).find(el => el.key === this.key)
            if(!range) return

            this.data = {...this.data, ...range.data}
            this.min = range.data.minValue
            this.max = range.data.maxValue
        })
    },

    setRangeValues() {
        Alpine.store('Archive').setQueryParams({
            [section.nameMin]: this.min,
            [section.nameMax]: this.max
        }, true)
    },

    adjustOtherSliderValue(slider, newValue) {
        if (slider === 'max') {
            if (newValue < this.max) return
            this.max = Math.min(newValue + this.step, this.data.max)
        } else {
            if (newValue > this.min) return
            this.min = Math.max(newValue - this.step, this.data.min)
        }
    },

    rangeSlider: {
        [':class']() { return this.minimum === this.maximum && 'is-disabled' },
        ['@click'](e) {
            if (e.target !== this.$el)
                return

            const diff = this.maximum - this.minimum
            const centerPosition = Math.abs((this.min - this.minimum) / diff) + Math.abs((this.min - this.max) / diff * .5)

            const getClickedPosition = (offset = 0) => {
                return (e.layerX + offset) / e.target.offsetWidth
            }

            if (getClickedPosition() >= centerPosition) {
                this.max = Math.min(Math.abs(Math.round(getClickedPosition(-8) * diff / this.step)) * this.step + this.step + this.minimum, this.data.max)
                this.adjustOtherSliderValue('min', this.max)
            } else {
                this.min = Math.max(Math.abs(Math.round(getClickedPosition(8) * diff / this.step)) * this.step - this.step + this.minimum, this.data.min)
                this.adjustOtherSliderValue('max', this.min)
            }

            this.setRangeValues()
        }
    },
    rangeMin: {
        [':name']() { return section.nameMin ?? 'min' },
        [':step']() { return this.step },
        [':min']() { return this.minimum },
        [':max']() { return Math.max(this.maximum - this.step, this.minimum) },
        [':disabled']() { return this.minimum === this.maximum },
        ['@change']() { this.setRangeValues() },
        ['@input']() { this.adjustOtherSliderValue('max', this.$el.valueAsNumber) }
    },
    rangeMax: {
        [':name']() { return section.nameMax ?? 'max' },
        [':step']() { return this.step },
        [':min']() { return Math.min(this.minimum + this.step, this.maximum) },
        [':max']() { return this.maximum },
        [':disabled']() { return this.minimum === this.maximum },
        ['@change']() { this.setRangeValues() },
        ['@input']() { this.adjustOtherSliderValue('min', this.$el.valueAsNumber) }
    },
    rangeIndicator: {
        [':style']() {
            const diff = this.maximum - this.minimum

            return {
                width: Math.min(Math.abs((this.min - this.max) / diff * 100), 100) + '%',
                left: Math.abs((this.min - this.minimum) / diff * 100) + '%'
            }
        }
    }
})

export const ArchiveFilterRangeSelect = (section) => ({
    key: section.key,
    data: section.data,
    min: 1970,
    max: 2999,

    get minAvailableOptions() {
        return (section.options ?? []).filter(option => option.key <= this.max)
    },
    get maxAvailableOptions() {
        return (section.options ?? []).filter(option => option.key >= this.min)
    },

    prepareOptionsCounts(options, revert = false) {
        const sum = options[!revert ? 'reduce' : 'reduceRight']((sum, currentValue, index) => {
            const newValue = sum + currentValue.count
            options[index].sumCount = newValue

            return newValue
        }, 0)

        return options
    },

    init() {
        this.min = this.data.minValue
        this.max = this.data.maxValue

        Alpine.effect(() => {
            const rangeSelect = (Alpine.store('Archive').filter ?? []).find(el => el.key === this.key)
            if(!rangeSelect) return

            this.data = {...this.data, ...rangeSelect.data}
            this.min = rangeSelect.data.minValue
            this.max = rangeSelect.data.maxValue
        })
    },

    inputMin: {
        [':class']() { return { 'is-empty': this.min === 1970 } },
    },
    selectMin: {
        [':name']() { return section.nameMin ?? 'min' },
        ['@change']() {
            this.$nextTick(() => Alpine.store('Archive').setQueryParams({
                [section.nameMin]: this.$el.value
            }, true))
        }
    },
    optionMin: {
        [':value']() { return this.option.key },
        [':selected']() { return this.min === this.option.key },
        [':disabled']() { return !this.minAvailableOptions.find(option => option.key === this.option.key) },
        [':style']() { return !this.minAvailableOptions.find(option => option.key === this.option.key) ? { display: 'none' } : {} },
        //['x-text']() { return `${this.option.label} (${this.minAvailableOptions.find(option => option.key === this.option.key)?.sumCount ?? 0})` }
        ['x-text']() { return `${this.option.label}` }
    },
    inputMax: {
        [':class']() { return { 'is-empty': this.max === 2999 } },
    },
    selectMax: {
        [':name']() { return section.nameMax ?? 'max' },
        ['@change']() {
            this.$nextTick(() => Alpine.store('Archive').setQueryParams({
                [section.nameMax]: this.$el.value
            }, true))
        }
    },
    optionMax: {
        [':value']() { return this.option.key },
        [':selected']() { return this.max === this.option.key },
        [':disabled']() { return !this.maxAvailableOptions.find(option => option.key === this.option.key) },
        [':style']() { return !this.maxAvailableOptions.find(option => option.key === this.option.key) ? { display: 'none' } : {} },
        //['x-text']() { return `${this.option.label} (${this.maxAvailableOptions.find(option => option.key === this.option.key)?.sumCount ?? 0})` }
        ['x-text']() { return `${this.option.label}` }
    }
})

export const ArchiveFilterSelection = () => ({
    get selection() {
        const selected = []
        const pushSelectedTag = (key, value) => {
            const section = Alpine.store('Archive').filter.find(section => key.includes(section.key))
            if (value === '') return;

            if (section?.type === 'term' || section?.type === 'select') {
                const term = section?.[section.type === 'select' ? 'options' : 'fields']?.find(term => term.key.toString() === value.toString())
                term && selected.push({ title: section?.title, label: term.label, value: value, key: key })
            } else if (section?.type === 'range') {
                const includedInQuery = Alpine.store('Archive').queryParams.hasOwnProperty(section.nameMin)
                    || Alpine.store('Archive').queryParams.hasOwnProperty(section.nameMax)

                includedInQuery && !selected.find(el => el.title === section.title) && selected.push({
                    title: section?.title,
                    label: `${parseInt(section.data.minValue).toLocaleString()} Kč - ${parseInt(section.data.maxValue).toLocaleString()} Kč`,
                    key: [section.nameMin, section.nameMax]
                })
            } else if (section?.type === 'range-select') {
                const includedInQuery = (Alpine.store('Archive').queryParams.hasOwnProperty(section.nameMin) && parseInt(Alpine.store('Archive').queryParams[section.nameMin]) !== 1970)
                    || (Alpine.store('Archive').queryParams.hasOwnProperty(section.nameMax) && parseInt(Alpine.store('Archive').queryParams[section.nameMax]) !== 2999)

                includedInQuery && !selected.find(el => el.title === section.title) && selected.push({
                    title: section?.title,
                    label: `${Math.max(parseInt(section.data.minValue), section.data.min)} - ${Math.min(parseInt(section.data.maxValue), section.data.max)}`,
                    key: [section.nameMin, section.nameMax]
                })
            } else if (key === (Alpine.store('Archive')?.queryParamsOrder['hledat'] ?? 'hledat')) {
                selected.push({ title: 'Hledaný výraz', label: value, value: value, key: key })
            }
        }

        Object.entries(Alpine.store('Archive').queryParams).forEach(([key, value]) => {
            const queryValue = typeof value === 'string' && value.includes(',') ? value.split(',') : value
            if (!['strana', 'razeni', 'razeni_podle'].includes(key))
                Array.isArray(queryValue) ? queryValue.forEach(term => pushSelectedTag(key, term))
                    : pushSelectedTag(key, queryValue)
        })

        return selected
    },
    title: {
        ['x-text']() { return this.term.title }
    },
    valueLabel: {
        ['x-text']() { return this.term.label }
    },
    removeTerm: {
        ['@click.prevent']() {
            !Array.isArray(this.term.key) ? Alpine.store('Archive').removeFilterTerm(this.term.key, this.term.value)
                : Alpine.store('Archive').removeFilterTermMulti(this.term.key)
        }
    },
    reset: {
        ['@click.prevent']() { Alpine.store('Archive').resetFilter() }
    }
})

export const ArchiveCategoryNav = () => ({
    tag: '',
    init() {
        this.tag = this.$el.querySelector('select[name="field"] option[selected]')?.value ?? ''
    },
    get counts() {
        return Alpine.store('Archive').categories.reduce((counts, category) => {
            return {...counts, ...{[category.slug]: category.count}}
        }, {})
    },
    ArchiveCategoryLink: {
        [':class']() {
            const targetCategory = this.$el.dataset.categorySlug,
                activeCategory = Alpine.store('Archive').queryParams.kategorie

            const categoryObject = Alpine.store('Archive').categories
                .find(category => category.slug === (targetCategory ?? ''))

            return {
                'is-empty': categoryObject && categoryObject.count < 1,
                'is-active': (activeCategory && activeCategory.includes(targetCategory))
                    || (!targetCategory && (activeCategory === 'all' || !activeCategory) )
            }
        },
        ['@click.prevent']() {
            const categorySlug = this.$el.dataset.categorySlug
            if (!categorySlug) this.tag = ''
            Alpine.store('Archive').changeCategory(categorySlug);
        }
    },
    ArchiveCategoryCount: {
        ['x-text']() { return this.counts ? this.counts[this.$el.parentElement?.dataset.categorySlug ?? ''] : '' }
    }
})

export const ArchiveFilterOpener = {
    '@click'() {
        this.filterOpened = !this.filterOpened
        this.filterOpened && document.dispatchEvent(new CustomEvent("updateFilterSticky"))
    }
}

export const ArchiveGrid = {
    ':class'() { return Alpine.store('Archive').isLoading && 'is-loading' },
    'x-html'() { return Alpine.store('Archive').posts.join('\n') }
}

export const ArchiveNoResults = {
    'x-show'() { return !Alpine.store('Archive').posts?.length }
}

export const ArchiveTotalCount = {
    'x-text'() { return Alpine.store('Archive').totalCount ?? 0 }
}

export const ArchiveResetFilter = {
    '@click.prevent'() { Alpine.store('Archive').resetFilter() }
}

export const ArchiveTagSelect = {
    '@change.prevent'() {
        this.$nextTick(() => { Alpine.store('Archive').setQueryParams({'zamereni': this.$el.value}, true) })
    }
}

export const ArchiveQuickLink = {
    '@click.prevent'() {
        const url = new URL(this.$el.href ?? this.$el.dataset.url),
            searchParams = url?.searchParams ?? null

        searchParams && Alpine.store('Archive')
            .setQueryParams(Object.fromEntries(searchParams.entries()), true, true, true)

        this.$el.classList.add('is-active')
    }
}

export const ArchiveStickyBar = {
    '@scroll.window.passive'() {
        this.$el.classList.toggle('is-sticked', this.$el.getBoundingClientRect().top <= 80)
    }
}

export const ArchiveOrder = {
    '@change.prevent'() {
        const order = this.$el.value
        !order.includes('|') ? Alpine.store('Archive').changeOrder(order)
            : Alpine.store('Archive').changeOrder(order.split('|')[1], order.split('|')[0]);
    }
}

export const ArchivePager = {
    ':class'() { return Alpine.store('Archive').isLoading && 'is-loading' },
    'x-html'() { return Alpine.store('Archive').pager }
}

export const ArchivePagerLink = {
    '@click.prevent'() {
        const pageUrl = new URLSearchParams((new URL(this.$el.getAttribute('href'))).search);
        Alpine.store('Archive').changePage(pageUrl.get(Alpine.store('Archive').queryParamsOrder['strana'] ?? 'strana') ?? 1);
    }
}

export const ArchiveLoadMore = {
    'x-show'() { return Alpine.store('Archive').nextPageUrl ?? false },
    ':href'() { return Alpine.store('Archive').nextPageUrl },
    ':class'() { return Alpine.store('Archive').isLoading && 'is-loading' },
    '@click.prevent'() {
        Alpine.store('Archive').loadNextPage()
    }
}